<template>
  <a-card :bordered="false">
    <a-spin :spinning="loading">
      <a-page-header
        style="border: 1px dashed rgb(235, 237, 240); margin-bottom: 35px"
        :title="title"
        :sub-title="number"
        @back="() => $router.go(-1)"
      />
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="预算">
              <a-input-number
                v-decorator="['budget', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区自营">
              <a-input-number
                v-decorator="['north_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区管委会">
              <a-input-number
                v-decorator="['north_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区济高">
              <a-input-number
                v-decorator="['north_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区自营">
              <a-input-number
                v-decorator="['south_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区管委会">
              <a-input-number
                v-decorator="['south_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区济高">
              <a-input-number
                v-decorator="['south_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="预算(不含税)">
              <a-input-number
                v-decorator="['ex_tax_budget', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区自营(不含税)">
              <a-input-number
                v-decorator="['ex_tax_north_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区管委会(不含税)">
              <a-input-number
                v-decorator="['ex_tax_north_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区济高(不含税)">
              <a-input-number
                v-decorator="['ex_tax_north_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区自营(不含税)">
              <a-input-number
                v-decorator="['ex_tax_south_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区管委会(不含税)">
              <a-input-number
                v-decorator="['ex_tax_south_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区济高(不含税)">
              <a-input-number
                v-decorator="['ex_tax_south_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-form-item>
            <a-col span="3" offset="19">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
          </a-form-item>
        </a-row>
      </a-form>
    </a-spin>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'

import { property_project_edit } from '@/api/property_project'

const { property_project_list } = require('@/api/property_project')
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      loading: false,
      form: this.$form.createForm(this),
      id: 0,
      title: '',
      number: ''
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      this.loading = true
      property_project_list({ id: this.id })
        .then(res => {
          const list = res.data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        }).finally(() => {
          this.loading = false
      })
    })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          values.budget = Math.round(values.budget * 100)
          values.north_self = Math.round(values.north_self * 100)
          values.south_self = Math.round(values.south_self * 100)
          values.north_jg = Math.round(values.north_jg * 100)
          values.south_jg = Math.round(values.south_jg * 100)
          values.north_gov = Math.round(values.north_gov * 100)
          values.south_gov = Math.round(values.south_gov * 100)

          values.ex_tax_budget = Math.round(values.ex_tax_budget * 100)
          values.ex_tax_north_self = Math.round(values.ex_tax_north_self * 100)
          values.ex_tax_south_self = Math.round(values.ex_tax_south_self * 100)
          values.ex_tax_north_jg = Math.round(values.ex_tax_north_jg * 100)
          values.ex_tax_south_jg = Math.round(values.ex_tax_south_jg * 100)
          values.ex_tax_north_gov = Math.round(values.ex_tax_north_gov * 100)
          values.ex_tax_south_gov = Math.round(values.ex_tax_south_gov * 100)
          if (values.budget !== (values.north_jg + values.north_self + values.north_gov + values.south_jg + values.south_self + values.south_gov)) {
            this.$message.warning('预算金额与分配金额不匹配')
            return
          }
          if (values.ex_tax_budget !== (values.ex_tax_north_jg + values.ex_tax_north_self + values.ex_tax_north_gov + values.ex_tax_south_jg + values.ex_tax_south_self + values.ex_tax_south_gov)) {
            this.$message.warning('预算(不含税)金额与分配金额不匹配')
            this.confirmLoading = false
            return
          }
          const is_ok = this.exTaxInTaxCheck(values.budget, values.ex_tax_budget) || this.exTaxInTaxCheck(values.north_self, values.ex_tax_north_self) ||
            this.exTaxInTaxCheck(values.north_gov, values.ex_tax_north_gov) || this.exTaxInTaxCheck(values.north_jg, values.ex_tax_north_jg) ||
            this.exTaxInTaxCheck(values.south_gov, values.ex_tax_south_gov) || this.exTaxInTaxCheck(values.south_jg, values.ex_tax_south_jg) ||
            this.exTaxInTaxCheck(values.south_self, values.ex_tax_south_self)
          if (is_ok) {
            this.$message.warning('含税金额不能小于不含税金额')
            this.confirmLoading = false
            return
          }
          this.loading = true
          property_project_edit(values, this.id)
            .then((res) => {
              this.handleGoBack()
            }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    exTaxInTaxCheck (tax, ex_tax) {
      if (ex_tax > tax) {
        return true
      } else {
        return false
      }
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载信息到表单`, data)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['name', 'budget',
          'north_self', 'north_gov', 'north_jg', 'south_self', 'south_gov', 'south_jg', 'ex_tax_budget',
          'ex_tax_north_self', 'ex_tax_north_gov', 'ex_tax_north_jg', 'ex_tax_south_self', 'ex_tax_south_gov', 'ex_tax_south_jg'
        ])
        console.log('formData', formData)
        this.title = formData.name
        this.number = '编号：' + data.number
        formData.budget = (formData.budget / 100).toFixed(2)
        formData.north_self = (formData.north_self / 100).toFixed(2)
        formData.south_self = (formData.south_self / 100).toFixed(2)
        formData.north_jg = (formData.north_jg / 100).toFixed(2)
        formData.south_jg = (formData.south_jg / 100).toFixed(2)
        formData.north_gov = (formData.north_gov / 100).toFixed(2)
        formData.south_gov = (formData.south_gov / 100).toFixed(2)

        formData.ex_tax_budget = (formData.ex_tax_budget / 100).toFixed(2)
        formData.ex_tax_north_self = (formData.ex_tax_north_self / 100).toFixed(2)
        formData.ex_tax_south_self = (formData.ex_tax_south_self / 100).toFixed(2)
        formData.ex_tax_north_jg = (formData.ex_tax_north_jg / 100).toFixed(2)
        formData.ex_tax_south_jg = (formData.ex_tax_south_jg / 100).toFixed(2)
        formData.ex_tax_north_gov = (formData.ex_tax_north_gov / 100).toFixed(2)
        formData.ex_tax_south_gov = (formData.ex_tax_south_gov / 100).toFixed(2)
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
