import request from '@/utils/request'

const property_projectApi = {
  property_project_list: '/property_project/',
  property_project_export: '/property_project/export/',
  property_project_create: '/property_project/',
  property_project_update: '/property_project/',
  property_project_delete: '/property_project/'
}
/**
 * 列表
 */
export function property_project_list (parameter) {
  return request({
    url: property_projectApi.property_project_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 导出
 */
export function property_project_export (parameter) {
  return request({
    url: property_projectApi.property_project_export,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}

/**
 * 添加
 */
export function property_project_create (parameter) {
  return request({
    url: property_projectApi.property_project_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function property_project_update (property_project_id) {
  return request({
    url: property_projectApi.property_project_update + property_project_id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 编辑
 */
export function property_project_edit (parameter, property_project_id) {
  return request({
    url: property_projectApi.property_project_update + property_project_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function property_project_delete (property_project_id) {
  return request({
    url: property_projectApi.property_project_delete + property_project_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
